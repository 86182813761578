const config = {
  isDev: process.env.NODE_ENV === 'development',
  isTest: process.env.NODE_ENV === 'test',
  isProd: process.env.NODE_ENV === 'production',
  api: {
    shopifyPaymentAppUrl: process.env.REACT_APP_SHOPIFY_PAYMENT_APP_URL ?? 'http://localhost:3001',
    shopifyPaymentApiKey: process.env.REACT_APP_SHOPIFY_PAYMENT_API_KEY ?? '',
  },
  merchantSignUpUrl: process.env.REACT_APP_MERCHANT_SIGNUP_URL ?? 'https://www.scalapay.com/vendors',
  partnerPortalDevUrl: process.env.REACT_APP_MERCHANT_DEVELOPER_URL ?? 'https://partner.scalapay.com/account/developer',
  shopifyPaymentGatewayRedirectPath: process.env.REACT_APP_PAYMENT_GATEWAY_REDIRECT_PATH ?? 'admin/settings/payments/alternative-providers/',
};

export type Config = typeof config;

export default config;
