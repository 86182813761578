import shopifyPaymentApi from '../lib/axios';
import type { MerchantApiKeyRequestBody } from '../types/MerchantApiKeyRequestBody';

class PaymentApi {
  async validateMerchantApiKey(scalapayApiKey: string, shopDomain: string) {
    try {
      const requestBody:MerchantApiKeyRequestBody = {
        shopDomain,
        scalapayApiKey
      };
      const response = await shopifyPaymentApi.post('/admin/merchant/ready', requestBody);
      return response;
    } catch (err) {
      return { status: 500 };
    }
  }
}

export const paymentApi = new PaymentApi();
